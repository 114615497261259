.Blog-post {
  max-width: 800px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 128px;
  text-align: left;
  padding: 0 16px;

  h1 {
    margin-bottom: 32px;
  }

  .img-wrap {
    width: 100%;
    height: 360px;
    border-radius: 32px;
    overflow: hidden;
    display: block;

    @media screen and (max-width: 650px) {
      height: 160px;
    }

    img {
      width: 100%;
    }
  }
}
