/* General Page */
.App {
  width: 100%;
}

body {
  font-size: 16px;
  line-height: 26px;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  margin: 0;
  background-color: #fffef4;
}

.body-wrap {
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

// Fonts

h1 {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 16px;

  @media screen and (max-width: 650px) {
    font-size: 42px;
    line-height: 42px;
  }
}

h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  margin-top: 30px;
  margin-bottom: 8px;
}

h3 {
  font-weight: 200;
  font-size: 20px;
  line-height: 26px;
}

h4 {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// Buttons
.button {
  margin-right: 16px;
  margin-bottom: 8px;
  display: inline-block;
  padding: 16px 32px;
  background: #6d67ff;
  color: white;
  font-weight: 600;
  letter-spacing: 0.3px;
  border-radius: 8px;
  text-decoration: none;
}

.icon-button {
  margin: 16px 0px 0 0;
  display: inline-block;
  padding: 8px 8px;
  border: 1px solid #ff33ff8c;
  border-radius: 8px;
  text-decoration: none;
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;

  &:hover {
    border: 1px solid #ff33ff;
  }
}

// Arrows
[class*="icono"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: white;
  box-sizing: border-box;
  &:after,
  &:before {
    content: "";
    box-sizing: border-box;
  }
}
[class*="icono-arrow1"] {
  width: 10px;
  height: 10px;
  border-width: 1px 1px 0 0;
  border-style: solid;
  margin: 10px;
  &:before {
    border-radius: 4px;
    right: 0px;
    top: -1px;
    position: absolute;
    height: 1px;
    box-shadow: inset 0 0 0 32px;
    transform: rotate(-45deg);
    width: 14px;
    transform-origin: right top;
  }
  &[class*="-left"] {
    transform: rotate(45deg);
  }
}

// Podcast box

.podcast-player {
  margin: 20px 10px -8px 10px;

  iframe {
    background: linear-gradient(
      40deg,
      rgba(40, 40, 40, 0.2) 0%,
      rgba(40, 40, 40, 1) 100%
    );
    border-radius: 16px;
  }
}

/* Blog Post */
.blog-card-wrap {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
}

.blogpost-preview {
  flex: 1 0 33.3333%;
  display: flex;
  max-width: 33.3333%;

  @media screen and (max-width: 650px) {
    width: auto;
    max-width: 100%;
    flex: 1 0 100%;
  }

  .blogpost-preview-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 10px;
    padding: 20px;
    box-shadow: 0px 6px 24px -4px rgba(0, 0, 0, 0.75);
    border-radius: 12px;
    text-align: left;
    background-color: #282828;
    color: white;

    h4 {
      margin: 4px 0 8px 0;
      color: #fffef4;
    }

    h2,
    h3 {
      margin: 0;
    }

    &:hover {
      background-color: black;
      .icon-button {
        border: 1px solid #ff33ff;
      }
    }
  }
}

/* Podcast Show */
